import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

const ListPanel = ({ digitalObjects, query }) => {
  const [filteredDO, setFilteredDO] = useState([])
  const { naId } = useParams()

  useEffect(() => {
    filterKeyword()
  }, [digitalObjects])

  const filterKeyword = useCallback(() => {
    let query = sessionStorage.getItem('search')?.toLowerCase()
    if (query) {
      query = query.replace(/"/g, '')
    }
    const result = digitalObjects.reduce((ac, cv) => {
      const conts = getAllcontributions(cv)
      const desc = cv.objectDescription
      const extract = cv.extractedText
      const otherExtTxt = cv?.otherExtractedText?.map((oet) => {
        return oet.contribution
      })

      let metaData = conts + ' ' + desc + ' ' + extract
      metaData += otherExtTxt ? ' ' + otherExtTxt.join(' ') : ''
      const terms = query?.split(' ')
      const hasQuery = terms?.every((term) =>
        metaData?.toLowerCase().includes(term)
      )

      if (hasQuery) {
        cv['isQueryMatch'] = true
      }
      return ac.concat(cv)
    }, [])
    setFilteredDO([...result])
  }, [digitalObjects])

  const getAllcontributions = (obj) => {
    const result = obj?.contributions
      ?.reduce((ac, cv) => {
        const _result = ac.concat(cv.contribution)
        return _result
      }, [])
      .join(' ')
    return result
  }

  const listPanel = useRef(null)
  const closeRef = useRef(null)
  const doRef = useRef([])

  const toggleCollapse = () => {
    closeRef.current.classList.toggle('open')
    listPanel.current.classList.toggle('open')
    closeRef.current.innerText = /open/gim.test(
      Array.from(listPanel.current.classList).join(' ')
    )
      ? 'Close'
      : 'Open'
  }

  const [doCount, setDoCount] = useState(0)

  const selectDO = (ele) => {
    const idx = parseInt(ele.currentTarget.outerText.match(/[0-9]+/gm)[0])
    const digiObjs = document.querySelectorAll('[role="tablist"] > div')
    digiObjs[idx - 1].click()
  }

  useEffect(() => {
    const result = digitalObjects.reduce((ac, cv) => {
      if (cv.isQueryMatch === true) {
        ++ac
      }
      return ac
    }, 0)
    setDoCount(result)
  })

  return (
    doCount > 0 && (
      <>
        <span
          className={`close ${doCount > 0 ? 'open' : ''}`}
          ref={closeRef}
          onClick={toggleCollapse}
        >
          {doCount > 0 ? 'Close' : 'Open'}
        </span>
        <div
          ref={listPanel}
          className={`list-panel ${doCount > 0 ? 'open' : ''}`}
        >
          <div className="collapse-panel">
            <div className="search-term-count">
              {doCount} Search term
              {doCount > 0 ? 's' : null} found
            </div>
            {digitalObjects.length > 0 && (
              <div className="list">
                {filteredDO.map((item, idx) => {
                  return (
                    item['isQueryMatch'] == true && (
                      <div className="item-url">
                        <Link
                          ref={(ele) => {
                            doRef.current[idx] = ele
                          }}
                          onClick={(ele) => {
                            selectDO(ele)
                          }}
                          to={{
                            pathname: `/id/${naId}?objectPage=${idx + 1}`,
                            hash: `object-thumb--${idx + 1}`,
                          }}
                        >
                          <div>
                            <span> Image # </span>
                            <span>{idx + 1}</span>
                          </div>
                        </Link>
                      </div>
                    )
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </>
    )
  )
}

ListPanel.defaultprops = {
  digitalObjects: [],
  query: '',
}

ListPanel.propTypes = {
  digitalObjects: PropTypes.array,
  query: PropTypes.string,
}

export default ListPanel
